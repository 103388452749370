import { useState } from "react";
import { Container, Typography, Box, Link, Button } from "@mui/material";
import { Image } from "./components/Image";
import { Exercise } from "./components/Exercise";
import Refresh from "@mui/icons-material/Refresh";
const exercises = require("./data/exercises.json");

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function App() {
  const [exerciseIdx, setExerciseIdx] = useState();
  const onClick = () => {
    const randomIdx = Math.floor(exercises.length * Math.random());
    setExerciseIdx(randomIdx);
  };
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h2" component="h2" gutterBottom>
          Workout Generator
        </Typography>
        <Button
          onClick={onClick}
          variant="contained"
          sx={{ width: "100%" }}
          startIcon={<Refresh />}
        >
          New Exercise
        </Button>
        {!isNaN(exerciseIdx) && <Exercise idx={exerciseIdx} />}
      </Box>
      <Copyright />
    </Container>
  );
}
