import { Container, Typography } from "@mui/material";
import { Image } from "./Image";
const exercises = require("../data/exercises.json");

const getReps = () => Math.floor(Math.random() * 20) + 1;
const getTime = () => (Math.floor(Math.random() * 10) + 1) * 10;

const Exercise = ({ idx }) => {
  const exercise = exercises[idx];
  return (
    <Container maxWidth="sm" sx={{ mt: 2 }}>
      <Typography variant="h4" component="h4" align="center">
        {exercise.label.toUpperCase()}
      </Typography>
      <Typography variant="h6" component="h6" align="center">
        {exercise.unit === "reps" && `${getReps()} Reps`}
        {exercise.unit === "time" && `${getTime()} Seconds`}
      </Typography>
      {exercise.images.map((img, i) => (
        <Image
          align="center"
          key={`${exercise.label}-${i}`}
          src={`${process.env.PUBLIC_URL}/images/exercises/${img}`}
        />
      ))}
    </Container>
  );
};

export { Exercise };
