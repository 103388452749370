import { Paper } from "@mui/material";

const Image = ({ src, ...props }) => (
  <Paper
    elevation="2"
    component="img"
    sx={{ my: 2, width: "100%" }}
    src={src}
  />
);

export { Image };
